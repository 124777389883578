import React from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const PenPaper = () => {
	return (
		<AccessibleIcon.Root label='Pen Paper Icon'>
			<svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g id='Group 49'>
					<path id='Vector 12' d='M15.0166 10.3889L15.0166 16.1667C15.0166 16.9031 14.4196 17.5 13.6833 17.5L2.33288 17.5C1.5965 17.5 0.999547 16.9031 0.999547 16.1667L0.999547 4.61117C0.999547 3.87479 1.5965 3.27783 2.33288 3.27783L8.88414 3.27783' stroke='#080593' strokeWidth='1.75'/>
					<path id='Vector 13' d='M10.4954 4.32832L12.659 2.13299C13.0036 1.78334 13.5623 1.78334 13.9069 2.13299L16.3759 4.63804C16.7205 4.98769 16.7205 5.55458 16.3759 5.90422L14.2122 8.09956M10.4954 4.32832L4.61037 10.2994V12.1851M10.4954 4.32832L14.2122 8.09956M14.2122 8.09956L8.3272 14.0707H6.46879M4.61037 12.1851V14.0707H6.46879M4.61037 12.1851L6.46879 14.0707' stroke='#080593' strokeWidth='1.75'/>
				</g>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default PenPaper;
