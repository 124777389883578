import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControlledInput, Modal } from '@components';
import { decipherPrism, encodePrism } from '@utils/rxss';
import { RxssPomsPayload } from '@ts/rxss';
import styles from './ControlledMeasurementInput.module.scss';

const ControlledMeasurementInput = ({
	control,
	handleUpdateActiveInput,
	label,
	id,
	values,
	centerInstruction,
}) => {
	const { setValue, getValues } = useFormContext();

	const handlePrismChange = (userFriendlyValue: string, fieldId: keyof RxssPomsPayload) => {
		const { strength, direction, basePropertyName } = decipherPrism(userFriendlyValue, fieldId);
		setValue(fieldId, strength, { shouldDirty: true, shouldValidate: true });
		setValue(basePropertyName, direction, { shouldDirty: true, shouldValidate: true });
	};

	return (
		<Modal.Trigger className={styles.container}>
			<Controller
				key={`rhfController-${label}`}
				name={id}
				control={control}
				defaultValue={null}
				rules={{
					required: {
						value: true,
						message: `${label} required`,
					},
				}}
				render={({ field: { onChange, value, ref, onBlur }, fieldState: { error } }) => {
					return (
						<ControlledInput
							key={`controlledInput-${label}`}
							ref={ref}
							value={id.includes('prism') ? encodePrism(id, getValues) : value}
							id={id}
							onChange={id.includes('prism') ? v => handlePrismChange(v, id) : onChange}
							errorMessage={error?.message as string}
							placeholder={label}
							onBlur={error?.message ? onBlur : undefined}
							values={values}
							required={true}
							withIcon={true}
							placeholderEffect={true}
							centerInstruction={centerInstruction}
							signalActivityToParent={() => {
								handleUpdateActiveInput(id);
							}}
							instruction='Make a Selection'
						/>
					);
				}}
			/>
		</Modal.Trigger>
	);
};

export default memo(ControlledMeasurementInput);
