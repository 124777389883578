import { Paragraph } from '@components/typography';
import variables from '@styles/export.module.scss';
import { Flex } from '@components';

type ErrorMessageProps = {
	message: string;
};

const ErrorMessage = ({ message }: ErrorMessageProps) => {
	return (
		<Flex align='center' justify='center'>
			<Paragraph style={{ color: variables.red2 }}>{message}</Paragraph>
		</Flex>
	);
};

export default ErrorMessage;
