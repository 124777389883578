import { OrderDataPOMS, OrderDetailPOMS } from '@ts/poms/orders';
import { formatDateLocale } from '@utils/dates';
import { formatCurrency, ORDER_HISTORY_ITEM_SEQUENCE, PRODUCT_TYPES } from '..';
import { normalizeProductTitle } from './normalize-product';

const deletedRegex = /\[DELETED\]/gm;

function alphabetizeGroupedLineItems(groupedLineItems) {
	const objCopy = groupedLineItems;
	Object.keys(objCopy).forEach(key => {
		objCopy[key] = objCopy[key].sort(function alphabetize(a, b) {
			return a.product_title > b.product_title;
		});
	});

	return objCopy;
}

function combineAllArrays(inputArr) {
	return inputArr.reduce(function combine(accumulator, tuple) {
		return [...accumulator, ...tuple[1]];
	}, []);
}

function groupByType(arr) {
	return arr.reduce(function reduceTypes(obj, item) {
		const key = item.product_type || 'MISC';

		// NOTE: Top Frame override
		if (key.includes(PRODUCT_TYPES.TOP_FRAME)) {
			if (!obj.hasOwnProperty(PRODUCT_TYPES.TOP_FRAME)) {
				obj[PRODUCT_TYPES.TOP_FRAME] = [];
			}

			obj[PRODUCT_TYPES.TOP_FRAME].push(item);

			return obj;
		}

		if (!obj.hasOwnProperty(key)) {
			obj[key] = [];
		}

		obj[key].push(item);

		return obj;
	}, {});
}

function orderCategories(grouped) {
	return Object.entries(grouped).sort(function ordering(a, b) {
		return ORDER_HISTORY_ITEM_SEQUENCE[a[0]] - ORDER_HISTORY_ITEM_SEQUENCE[b[0]];
	});
}

function cleanDeletedItemTitles(items) {
	return items.map(item => {
		if (item.product_type !== 'DELETED') return item;

		return {
			...item,
			product_title: item.product_title.replaceAll(deletedRegex, ''),
			variant_title: item.variant_title.replaceAll(deletedRegex, ''),
		};
	});
}

export const normalizeOrderHistory = ({ orders, pagination }: OrderDataPOMS): OrderDataPOMS => {
	if (!orders) return undefined;

	const normalizedOrders = orders.map(order => {

		const normalizedLineItems = order.line_items.map(line => {
			return {
				...line,
				product_title: normalizeProductTitle(line.product_title, line.product_type),
			}
		}) 

		const organizedLineItems = cleanDeletedItemTitles(
			combineAllArrays(orderCategories(alphabetizeGroupedLineItems(groupByType(normalizedLineItems))))
		);

		return {
			...order,
			date: formatDateLocale(order.date),
			line_items: organizedLineItems,
		};
	});

	const normalizedPagination = {
		...pagination,
		series: pagination.series.map(n => Number(n)),
	};

	return { orders: normalizedOrders, pagination: normalizedPagination };
};

export const normalizeOrderDetails = (order: OrderDetailPOMS): OrderDetailPOMS => {
	if (!order) return undefined;

	const {
		date,
		discount_applications,
		line_items,
		total_discounts,
		total_tax,
		shipping_lines,
		total_price,
		last_status,
		subtotal_price,
		...rest
	} = order;

	let baseFrameTitle;

	const isCancelled = last_status.title === 'Cancelled';
	const copiedLineItems = line_items.map(item => {
		if (item.product_type.includes(PRODUCT_TYPES.BASE_FRAME)) baseFrameTitle = item.product_title;
		if (item.product_type === PRODUCT_TYPES.LENS) {
			return {
				...item,
				variant_title: baseFrameTitle?.split('The ')[1] ?? baseFrameTitle,
			}
		}

		return { 
			...item,
			product_title: normalizeProductTitle(item.product_title, item.product_type),
		};
	});
	const formattedTotalPrice = formatCurrency({ amount: total_price, minDigits: 2, maxDigits: 2 });

	return {
		...rest,
		discount_applications,
		shipping_lines,
		date: formatDateLocale(date),
		last_status,
		line_items: cleanDeletedItemTitles(
			combineAllArrays(orderCategories(alphabetizeGroupedLineItems(groupByType(copiedLineItems))))
		),
		subtotal_price: formatCurrency({ amount: subtotal_price, minDigits: 2, maxDigits: 2 }),
		total_discounts: formatCurrency({ amount: total_discounts, minDigits: 2, maxDigits: 2 }),
		total_price: isCancelled && Number(total_price) !== 0 ? `(${formattedTotalPrice})` : formattedTotalPrice,
		total_tax: formatCurrency({ amount: total_tax, minDigits: 2, maxDigits: 2 }),
	};
};
