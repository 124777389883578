/* eslint-disable max-lines */
import { useCallback, useState, useRef } from 'react';
import cn from 'classnames';
import { ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS, PRESCRIPTION_LABELS } from '@constants';
import { Attention, Button, Checkmark, File, Flex, Lozenge, Modal, PenPaper, PhotoPd, Upload } from '@components';
import { Caption, Label, Paragraph } from '@components/typography';
import { LineItemPOMS } from '@ts/poms/orders';
import variables from '@styles/export.module.scss';
import { RXSSProvider } from '@context';
import { RxssWizard } from '@components/rxss';
import { useIsMobile } from "@utils/hooks";
import { sendPupillaryDistance } from '@services/poms/operations/send-pupillary-distance';
import styles from './PrescriptionSubmission.module.scss';

type PrescriptionSubmissionProps = {
	line: LineItemPOMS;
	isPdNeeded?: boolean;
	orderNumber?: string;
	submission_method?: string;
	prescription_image?: string;
	limitManualRX?: boolean;
};

const PrescriptionSubmission = ({ line, isPdNeeded = false, orderNumber, prescription_image, submission_method = '', limitManualRX = false }: PrescriptionSubmissionProps) => {
	const [method, setMethod] = useState(!!prescription_image ? 'photo' : line.rx_needed ? '' : submission_method || 'manual');
	const [isRxNeeded, setIsRxNeeded] = useState(line.rx_needed);
	const [fileName, setFileName] = useState(prescription_image);
	const [isLoading, setIsLoading] = useState(false);
	const [uploadError, setUploadError] = useState(null);
	const [validationError, setValidationError] = useState(null);
	const [hasMeasuredPd, setHasMeasuredPd] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const hiddenFileInput = useRef(null);
	const isMobile = useIsMobile();

	const handleUploadPrescription = useCallback(
		async files => {
			setIsLoading(true);
			try {
				const data = new FormData();
				data.append('file', files[0]);
				data.append('job_id', line.job_id.toString());

				const extension = files[0].name?.match(/\.([0-9a-z]+)$/i)?.[1]?.toLowerCase();
				if (!ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS.includes(extension)) {
					setIsLoading(false);
					return setValidationError(true);
				}

				const response = await fetch(`${process.env.NEXT_PUBLIC_POMS_URL}/api/prescription/upload`, {
					method: 'POST',
					body: data,
				});

				if (response.status !== 200) {
					throw new Error(`HTTP Response ${response.status}: [POMS] Prescription Upload Failed`);
				}

				setIsLoading(false);
				setFileName(files[0].name);
				setMethod('photo');
				setIsRxNeeded(false);
				setHasMeasuredPd(true);
			} catch (error) {
				setIsLoading(false);
				return setUploadError(error);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[line.job_id]
	);

	const handleUploadPd = useCallback(
		async pd => {
			try {
				const response = await sendPupillaryDistance(orderNumber, pd);

				if (!response) {
					throw new Error(`HTTP Response: [POMS] PD Upload Failed`);
				}

				setHasMeasuredPd(true);
			} catch (error) {
				return setUploadError(error);
			}
		}, [orderNumber]);

	const handleOnSubmit = (response: boolean) => {
		setMethod('manual');
		setFileName(null);
		setIsRxNeeded(!response);
		setHasMeasuredPd(response);
	}

	const handleClickInput = () => {
		setUploadError(null);
		setValidationError(null);
		hiddenFileInput.current.click();
	};

	const isSuccess = (!!fileName && hasMeasuredPd) || (!isRxNeeded && !isPdNeeded) || method === 'doctor' || method === 'manual' || method === 'remind';

	const labelClassnames = cn(styles['label'], {
		[styles['label--error']]: isRxNeeded || isPdNeeded || !fileName,
		[styles['label--success']]: isSuccess,
	});

	let label = PRESCRIPTION_LABELS.MISSING;

	if (!isRxNeeded) {
		label = (isPdNeeded && !hasMeasuredPd && method === 'photo') ? PRESCRIPTION_LABELS.MISSING_PD : PRESCRIPTION_LABELS.SUCCESS;
	}

	let methodMessage = '';

	switch (method) {
		case 'manual':
			methodMessage = 'Entered in manually';
			break;
		case 'photo':
			methodMessage = 'Photo of prescription uploaded';
			break;
		case 'doctor':
			methodMessage = 'Eye doctor will be contacted';
			break;
		default:
			methodMessage = 'Entered in manually';
			break;
	}

	return (
		<Flex column>
			<Flex className={labelClassnames} gap={3} align={isMobile ? 'start' : 'center'}>
				{isSuccess ? <Checkmark width={28} height={24} circle /> : <Attention label='prescription error' width={28} height={24}/>}
				<Paragraph>{label}</Paragraph>
			</Flex>
			<Flex column align='start' className={styles.submission}>
				{isRxNeeded && (
					<Flex gap={3} fullWidth>
						<Button
							color='white'
							disabled={isLoading}
							onClick={() => handleClickInput()}
							style={{ minHeight: '12.8rem', minWidth: '50%' }}
							data-rxss-method='photo'
						>
							<Flex column fullWidth align='center' justify='center' gap={3}>
								<Upload />
								<>
									<input
										type='file'
										accept={ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS.map(ext => `.${ext}`).join(', ')}
										ref={hiddenFileInput}
										className={styles['hidden-upload']}
										onChange={({ target }) => {
											if (target.files.length) return handleUploadPrescription(target.files);
										}}
									/>
									<span>{isLoading ? 'Loading...' : 'Upload Prescription'}</span>
								</>
							</Flex>
						</Button>
						<Flex style={{ width: '100%' }}>
							<Modal open={isModalOpen && !limitManualRX} onOpenChange={setIsModalOpen}>
								<div className={styles['manual-prescription-container']} onClick={() => setIsModalOpen(true)}>
									<Button fullWidth color='white' disabled={isLoading || limitManualRX} style={{ minHeight: '12.8rem' }} data-rxss-method='manual'>
										<Flex column fullWidth align='center' gap={3}>
											<PenPaper />
											<span>Manually Enter Prescription</span>
										</Flex>
									</Button>
									<Lozenge extraClasses={styles['upload-lozenge']} color='white' backgroundColor={variables.blue1} shape='square'>
										Get your frames faster!
									</Lozenge>
								</div>
								<Modal.Content
									removePadding
									className={styles['prescription-modal']}
									customClose
									onPointerDownOutside={(e) => e.preventDefault()}
								>
									<RXSSProvider baseFrameImage={line.image} bundleId={line.shopify_bundle_id} orderNumber={orderNumber} productId={`${line.product_id}`}>
										<RxssWizard onOpenChange={setIsModalOpen} onSubmit={handleOnSubmit} />
									</RXSSProvider>
								</Modal.Content>
							</Modal>
						</Flex>
					</Flex>
				)}
				{!isRxNeeded && (
					<Flex column>
						<Label>Method</Label>
						<Paragraph>{methodMessage}</Paragraph>
					</Flex>
				)}
				{method === 'photo' && (
					<Flex gap={3} fullWidth align='center' justify='between' className={styles['photo-placeholder']}>
						<File color={variables.green4} className={styles['file-icon']}/>
						<Flex justify='start' fullWidth>
							<Caption className={styles.fileName} small style={{ color: variables.gray4 }}>
								{fileName ?? 'Mock_File.png'}
							</Caption>
						</Flex>
					</Flex>
				)}
				{method === 'photo' && (
					<PhotoPd isAccount={true} isPdNeeded={isPdNeeded} isMeasuredPd={hasMeasuredPd} submit={handleUploadPd} />
				)}
				{uploadError ? (
					<Paragraph className={styles.error}>
						There was an error with the submission upload. Please contact our Customer Service team.
					</Paragraph>
				) : null}
				{validationError ? (
					<Paragraph className={styles.error}>
						You have selected an invalid file type. Please upload a file with one of the following extensions:{' '}
						{ALLOWED_PRESCRIPTION_UPLOAD_EXTENSIONS.join(', ')}.
					</Paragraph>
				) : null}
			</Flex>
		</Flex>
	);
};

export default PrescriptionSubmission;
