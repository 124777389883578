import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { RxForm, RxssPomsPayload } from '@ts/rxss';
import { RxUploadIssue } from '@services/poms';
import { RXContextProps } from '@ts/contexts';
import { RXSS_FIELD_OPTIONS, RxMeasurementInput } from '@utils/constants/rxss';

export const decipherPrism = (userFriendlyValue: string, fieldId: keyof RxssPomsPayload) => {
	if (!userFriendlyValue || !fieldId) return null;
	// userFriendlyValue will be something like "3.25 △ BO"
	const [strength, direction] = userFriendlyValue.split(' △ ');

	const basePropertyName = getBasePropertyName(fieldId);

	return {
		strength,
		direction,
		basePropertyName,
	};
};

export const encodePrism = (fieldId: keyof RxssPomsPayload, getValues: UseFormGetValues<FieldValues>) => {
	if (!fieldId || !getValues) return null;
	const basePropertyName = getBasePropertyName(fieldId);

	const [strength, direction] = getValues([fieldId, basePropertyName]);

	if (!strength || !direction) {
		return null;
	}
	return `${strength} △ ${direction}`;
};

export const getBasePropertyName = (fieldId: keyof RxssPomsPayload) => {
	if (!fieldId) return null;
	const leftPrism = fieldId.includes('left');
	const horizontalPrism = fieldId.includes('horizontal');
	const name: keyof RxssPomsPayload = `${leftPrism ? 'left_' : 'right_'}prism_${horizontalPrism ? 'horizontal_' : 'vertical_'}base`;
	return name;
};

export const hasRxErrors = (errors: RxUploadIssue[], errorMessage: string) => {
	if (!errors || errors.length === 0) {
		return false;
	}
	return errors.some(error => {
		const lowerCaseErrorMessage = error.message.toLowerCase();
		return lowerCaseErrorMessage.includes(errorMessage);
	});
}


export const getTogglableInputs = (selectedFields: Array<keyof RxForm>, hasTwoPds: boolean) => {
	let fields = selectedFields;
	if (!fields.includes('pd')) fields = [...fields, 'pd'];

	const inputs = Object.values(RXSS_FIELD_OPTIONS).reduce(
		(accumulator, current) => {
			const fieldIndex = fields.indexOf(current.id);
			if (fieldIndex === -1) return accumulator;

			let index = accumulator[1];

			current.measurements.forEach((measurement, measurementIndex) => {
				index += measurementIndex;

				measurement.inputs.forEach(({ id, values, label }, inputIndex) => {
					// PD Check for Removing Option without Input
					if (current.id === 'pd') {
						if (hasTwoPds) {
							if (inputIndex === 2) return;
						} else {
							if (inputIndex !== 2) return;
							inputIndex -= 2;
						}
					}

					index += inputIndex;
					accumulator[1] = index + 1;

					accumulator[0][id] = {
						id,
						label,
						values,
						index: index,
						centerInstruction: measurement.centerInstruction,
					};
				});
			});

			return accumulator;
		},
			[{} as RXContextProps['togglableInputs'], 0] as [RXContextProps['togglableInputs'], number]
	);
	return inputs[0];
}

export const getMissingFieldsRxss = (formValues: FieldValues, selectedFields: (keyof RxForm)[], hasTwoPds: boolean) => {
	const newMissingFields: Array<RxMeasurementInput> = [];
	const realSelectedFields = selectedFields.includes('pd') ? selectedFields : [...selectedFields, 'pd'];

	realSelectedFields.forEach(fieldId => {
		const { measurements } = RXSS_FIELD_OPTIONS[fieldId];
		measurements.forEach(({ inputs }) => {
			if (fieldId === 'pd') {
				if (hasTwoPds) {
					const leftPd = inputs.find(input => input.id === 'left_pd');
					const rightPd = inputs.find(input => input.id === 'right_pd');

					if (!formValues['right_pd']) newMissingFields.push(rightPd);
					if (!formValues['left_pd']) newMissingFields.push(leftPd);
				} else if (!formValues['single_pd']) {
					const singlePd = inputs.find(input => input.id === 'single_pd');
					newMissingFields.push(singlePd);
				}
				return;
			}

			inputs.forEach(input => {
				if (!formValues[input.id]) {
					newMissingFields.push(input);
				}
			});
		});
	});

	return newMissingFields;
}
