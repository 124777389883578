import React from 'react';
import { Heading, Paragraph } from '@components/typography';
import { Flex } from '@components';

type PriceProps = {
	headingTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	isHeading?: boolean;
	label: string;
	price: string;
};

const Price = ({ headingTag, isHeading = false, label, price }: PriceProps) => (
	<Flex justify='between' gap={3} style={{ margin: '1.6rem 0 0.8rem' }}>
		<Paragraph style={{ marginLeft: '1.6remrem' }}>{label}</Paragraph>
		{isHeading ? <Heading tag={headingTag}>{price}</Heading> : <Paragraph>{price}</Paragraph>}
	</Flex>
);

export default Price;
