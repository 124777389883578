/* eslint-disable max-lines */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { m } from 'framer-motion';
import { getTogglableInputs } from '@utils/rxss';
import { MeasurementCell, PDMeasurementCell } from '@components/rxss';
import { Button, Flex, Modal, PdToolModal, Img, WheelPicker } from '@components';
import { Heading, Paragraph } from '@components/typography';
import { useIsMobile } from '@utils/hooks';
import { RXSS_FIELD_OPTIONS, RXSS_STEPS } from '@utils/constants/rxss';
import { RxssPomsPayload, bulkResetRxFields } from '@ts/rxss';
import { useRXSSContext } from '@context';
import { orderSelectedRxFields } from '@utils/strings';
import styles from './Form.module.scss';
import WheelPickerArms from './WheelPickerArm';

const Form = () => {
	const isMobile = useIsMobile();
	const {
		activeInput,
		isPickerOpen,
		pdToolHasError,
		togglableInputs,
		setHasTwoPds,
		hasTwoPds,
		setActiveInput,
		setPdToolHasError,
		selectedFields,
		baseFrameImage,
		setIsNextStepBlocked,
		formData,
		isPdFieldSelected,
		setTogglableInputs,
		step,
	} = useRXSSContext();

	const selectedFieldsWithoutPd = orderSelectedRxFields((selectedFields.includes('pd') ? selectedFields.filter(field => field !== 'pd') : selectedFields) as string[]);
	const pdLabels = useMemo(() => new Set(['Pupillary Distance', 'PD OD', 'PD OS']), []);
	const togglableInputValues = useMemo(() => {
		const values = Object.values(togglableInputs);
		if (!isPdFieldSelected && !pdToolHasError) {
			return values.filter(({ label }) => !pdLabels.has(label));
		}
		return values;
	}, [isPdFieldSelected, pdLabels, togglableInputs]);
	const isPdFieldOpen = isPickerOpen && pdLabels.has(activeInput?.label);

	const {
		control,
		setValue,
		getValues,
		trigger: validateForm,
		formState: { isValid, touchedFields },
	} = useFormContext();

	const [pdModalOpen, setPdModalOpen] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setPdToolInit] = useState(false);
	const pdValue = getValues('single_pd');

	const handleToggleTwoPds = useCallback(() => {
		bulkResetRxFields('pd', setValue);
		setHasTwoPds(!hasTwoPds)
	}, [hasTwoPds, setValue]);

	const handleUpdateActiveInput = useCallback(
		(id: keyof RxssPomsPayload) => {
			setActiveInput(togglableInputs[id]);
		},
		[activeInput, togglableInputs]
	);

	const handleHeaderNavigation = (action: 'BACK' | 'NEXT') => {
		const { id, index } = activeInput;
		const foundInput = Object.values(togglableInputs).find(
			input => input.index === (action === 'BACK' ? index - 1 : index + 1)
		);
		handleUpdateActiveInput((foundInput?.id || id) as keyof RxssPomsPayload);
	};

	useEffect(() => {
		validateForm(Object.keys(touchedFields));
	}, [touchedFields, validateForm, isPickerOpen, activeInput]);

	useEffect(() => {
		const hasPdData = !!formData['left_pd'];
		if (!isPdFieldSelected && !hasPdData) {
			setIsNextStepBlocked(hasPdData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPdFieldSelected]);

	useEffect(() => {
		const hasPdData = getValues('left_pd') || getValues('right_pd') || getValues('single_pd');
		setIsNextStepBlocked(!isValid || !hasPdData);
	}, [getValues, isValid, touchedFields, pdValue, hasTwoPds, step]);

	useEffect(() => {
		const togglableInputs = getTogglableInputs(selectedFields, hasTwoPds);
		setTogglableInputs(togglableInputs);
	}, [hasTwoPds]);

	const wheelPickerArms = useMemo(() => {
		return <WheelPickerArms activeInput={activeInput} getValues={getValues} setValue={setValue} />;
	}, [activeInput, getValues, setValue]);

	return (
		<m.div
			animate={step === RXSS_STEPS.FORM ? 'inView' : step === RXSS_STEPS.REVIEW ? 'accepted' : 'outOfView'}
			initial='outOfView'
			transition={{ type: 'spring', stiffness: 256, damping: 24, duration: 0.25, mass: 1 }}
			style={{
				position: 'absolute',
				top: 0,
			}}
			variants={{
				inView: {
					x: 0,
					y: 0,
					opacity: 1,
				},
				outOfView: {
					x: '125%',
					y: 0,
				},
				accepted: {
					x: '-125%',
					y: 0,
				},
			}}
		>
			<Modal>
				<Flex
					fullHeight
					fullWidth
					column
					center
					justify='start'
					style={{ height: 'auto', paddingBottom: isPdFieldOpen ? '18rem' : '', position: 'relative' }}
				>
					<div className={styles['image-container']}>
						<Img className={styles['image']} src={baseFrameImage.src} alt={baseFrameImage.altText} />
					</div>
					<Flex fullWidth column gap={2} className={styles['heading']}>
						<Heading tag='h6'>
							Enter Your Prescription Details
						</Heading>
						<Paragraph>
							Refer to your most recent Rx for this information.
						</Paragraph>
					</Flex>
					<Flex
						className={styles['form-content']}
						fullHeight
						fullWidth
						column
						center
						gap={4}
						justify='start'
					>
						{[...selectedFieldsWithoutPd, 'pd'].map(field =>
							field !== 'pd' ? (
								<MeasurementCell
									key={`measurementCell-${field}`}
									control={control}
									id={`measurementCell-${RXSS_FIELD_OPTIONS[field].id}`}
									measurements={RXSS_FIELD_OPTIONS[field].measurements}
									handleUpdateActiveInput={handleUpdateActiveInput}
								/>
							) : (
								<PDMeasurementCell
									key={`measurementCell-${field}`}
									control={control}
									handleToggleTwoPds={handleToggleTwoPds}
									id={`pd-measurement-${RXSS_FIELD_OPTIONS[field].id}`}
									measurements={RXSS_FIELD_OPTIONS[field].measurements}
									pdToolHasError={pdToolHasError}
									setPdModalOpen={setPdModalOpen}
									handleUpdateActiveInput={handleUpdateActiveInput}
									pdValue={pdValue}
								/>
							)
						)}
					</Flex>
				</Flex>
				{!pdValue && (
					<PdToolModal
						open={pdModalOpen}
						setOpen={setPdModalOpen}
						setPdToolInit={setPdToolInit}
						setPdToolHasError={hasError => setPdToolHasError(hasError)}
						pdValueHandler={value => {
							setValue('single_pd', value, { shouldDirty: true, shouldValidate: true });
							setPdModalOpen(false);
						}}
						strategy='onReady'
						className={styles.pdModal}
						classNameOverlay={styles.pdModalOverlay}
					/>
				)}
				{isMobile && (
					<Modal.Content
						removePadding
						style={{ maxHeight: '346px' }}
						includeCloseButton={false}
						className={styles.pickerModal}
						onCloseAutoFocus={e => e.preventDefault()}
					>
						<Flex fullWidth justify='between' align='center' position='absolute' className={styles.pickerHeader}>
							{activeInput?.index === 0 ? null : (
								<Button
									linkStyle
									size='small'
									onClick={() => handleHeaderNavigation('BACK')}
									withChevron
									chevronDirection='left'
									label='Back'
									chevronPosition='front'
									extraClasses={styles.pickerBack}
								/>
							)}
							<Paragraph className={styles.pickerTitle}>{activeInput?.label}</Paragraph>
							{activeInput?.index === togglableInputValues.length - 1 ? (
								<Modal.Close asChild>
									<Button linkStyle size='small' label='Done' extraClasses={styles.pickerNext} />
								</Modal.Close>
							) : (
								<Button
									linkStyle
									size='small'
									onClick={() => handleHeaderNavigation('NEXT')}
									withChevron
									chevronDirection='right'
									label='Next'
									extraClasses={styles.pickerNext}
								/>
							)}
						</Flex>
						<WheelPicker parentHeight={32 * (6 * 2 - 1)} visibleOptions={6} itemHeight={32}>
							{wheelPickerArms}
						</WheelPicker>
					</Modal.Content>
				)}
			</Modal>
		</m.div>
	);
};

export default memo(Form);
