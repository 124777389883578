/* eslint-disable max-lines */
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { Checkmark, Chevron, Flex } from '@components';
import { Caption } from '@components/typography';
import { useClickOutside, useIsMobile } from '@utils/hooks';
import sleep from '@utils/sleep';
import { useRXSSContext } from '@context';
import styles from '../Input/Input.module.scss';
import dropdownStyles from './ControlledInput.module.scss';

export type ControlledOption = { copy: string; value: string | number };
export type ControlledValues = string[] | ControlledOption[];

type ControlledInputProps = JSX.IntrinsicElements['textarea'] & {
	/** If an unselectable instruction option has been provided, setting this value true will render the instruction in the middle of the value array */
	centerInstruction?: boolean;
	controllerState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	errorMessage?: string;
	instruction?: string;
	onChange: (v: string | ControlledOption) => void;
	placeholderEffect?: boolean;
	values: ControlledValues;
	withIcon?: boolean;
	signalActivityToParent?: (id: string) => void;
};

const CLOSING_DELAY = 150;

const ControlledInput = forwardRef<HTMLInputElement, ControlledInputProps>(
	({
		centerInstruction,
		className,
		errorMessage,
		id,
		instruction,
		placeholder,
		required,
		value,
		values,
		withIcon,
		placeholderEffect = true,
		controllerState: inheritedControllerState,
		onChange,
		onBlur,
		signalActivityToParent,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
	}: ControlledInputProps, ref) => {
		const [isControllerVisible, setControllerVisible] = useState(false);
		const [selected, setSelection] = useState(value ?? instruction ?? values[0]);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const selectIndex = values?.findIndex(({ value }) => value === selected);

		const isMobile = useIsMobile();
		const { activeInput, setIsPickerOpen } = useRXSSContext();

		const isActiveInput = activeInput?.id === id;

		const inputRef = useRef<HTMLTextAreaElement>();
		const containerRef = useRef<HTMLDivElement>();
		const dropdownRef = useRef<HTMLDivElement>();
		const selectedItemRef = useRef<HTMLLIElement>();

		useClickOutside(containerRef, () => updateControllerVisibility(false));
		const container = document.getElementById('pprx-container');

		useEffect(() => {
			if (isActiveInput && isMobile) {
				inputRef.current?.scrollIntoView();
				container.scrollBy({ top: -15 });
			}
		}, [isActiveInput, id, isMobile, container]);

		//--- Functionality ---//
		const updateControllerVisibility = useCallback(
			(newValue: boolean) => {
				if (inheritedControllerState) return inheritedControllerState[1](newValue);
				setControllerVisible(newValue);
			},
			[inheritedControllerState]
		);

		const generateDisplayedValue = useCallback(({ val, inst, cntrVisible }) => {
			if (val) return val;
			else if (cntrVisible && inst) return inst;
			else return '';
		}, []);

		const handleSelect = useCallback(
			async (index, option) => {
				updateControllerVisibility(false);
				setSelection(option.value);
				if (inputRef.current) inputRef.current.value = option.value;
				onChange(option.value);
			},
			[onChange, updateControllerVisibility]
		);

		const handleSelectKeyDown = useCallback(
			(index, option) => e => {
				switch (e.key) {
					case ' ':
					case 'SpaceBar':
					case 'Enter':
						e.preventDefault();
						handleSelect(index, option.value);
						break;
					default:
						break;
				}
			},
			[handleSelect]
		);

		const handleControllerVisibilityKeyDown = useCallback(
			({ event, isVisible }) => {
				switch (event.key) {
					case ' ':
					case 'SpaceBar':
					case 'Enter':
						event.preventDefault();
						updateControllerVisibility(!isVisible);
						break;
					default:
						break;
				}
			},
			[updateControllerVisibility]
		);

		//--- Calculated Values ---//
		const derivedControllerVisibility = inheritedControllerState ? inheritedControllerState[0] : isControllerVisible;
		const displayValue = generateDisplayedValue({ val: value, inst: instruction, cntrVisible: isActiveInput });

		// Scroll to Selected
		useEffect(() => {
			if (selectedItemRef.current && dropdownRef.current && derivedControllerVisibility) {
				dropdownRef.current.scrollTo({ top: selectedItemRef.current.offsetTop - 48 });
			}
			setIsPickerOpen(derivedControllerVisibility);
		}, [selectIndex, derivedControllerVisibility, setIsPickerOpen]);

		//--- Value Mapping & Class Assignments ---//
		const valuesToMap: ControlledValues = useMemo(() => {
			const copy: ControlledValues = [...values] as ControlledValues;
			if (instruction && centerInstruction) {
				copy.splice(copy.length / 2, 0, instruction);
				return copy;
			}

			if (instruction) {
				copy.splice(0, 0, instruction);
			}
			return copy as ControlledValues;
		}, [centerInstruction, instruction, values]);

		const containerClasses = useMemo(
			() =>
				cn(
					styles['container'],
					styles['transparent-caret'],
					{
						[styles['container--has-error']]: errorMessage,
						[styles['container--with-icon__right']]: withIcon,
						[styles['container--placeholder-effect']]: placeholderEffect,
						[styles['instruction-selected']]: displayValue === instruction,
						[styles['input-active']]: isActiveInput && isMobile,
					},
					className
				),
			[className, displayValue, errorMessage, instruction, isActiveInput, isMobile, placeholderEffect, withIcon]
		);

		const mappedInputOptions = valuesToMap.map((option, index) => (
			<li
				key={`${option.copy}-${index}`}
				ref={(selectIndex < 0 && option === instruction) || index === selectIndex ? selectedItemRef : undefined}
				id={`${option.copy}-${index}`}
				className={cn(dropdownStyles['option'], {
					[dropdownStyles['is-selected']]: selected === option.value,
					[dropdownStyles['is-instruction']]: option === instruction,
				})}
				role='option'
				aria-selected={index === selectIndex}
				tabIndex={option !== instruction ? 0 : -1}
				onClick={() => handleSelect(index, option)}
				onKeyDown={option !== instruction ? handleSelectKeyDown(index, option) : undefined}
			>
				<Checkmark />
				<Caption className={dropdownStyles['caption']}>{typeof option === 'string' ? option : option.copy}</Caption>
			</li>
		));

		const resizeTextArea = () => {
			inputRef.current.style.height = "inherit";
			inputRef.current.style.height = inputRef.current.scrollHeight + 2 + 'px';
		};
		useEffect(resizeTextArea, [displayValue]);

		return (
			<div
				className={containerClasses}
				ref={containerRef}
				data-input-option={id}
				data-controlled-input={true}
				onClick={() => {
					signalActivityToParent(id);
				}}
			>
				<textarea
					id={`controlled-input-${id}`}
					ref={inputRef}
					placeholder={placeholder}
					value={displayValue}
					onInput={e => e.preventDefault()}
					onFocus={() => updateControllerVisibility(true)}
					onBlur={async e => {
						await sleep(CLOSING_DELAY);
						if (onBlur) {
							onBlur(e);
						}
					}}
					data-use-gray-bg={true}
					rows={1}
				/>

				<label htmlFor={id}>
					{placeholder}
					{required && <span className={styles['asterisk']}>*</span>}
				</label>

				{withIcon && (
					<div
						onClick={() => updateControllerVisibility(!derivedControllerVisibility)}
						onKeyDown={event => handleControllerVisibilityKeyDown({ event, isVisible: !derivedControllerVisibility })}
					>
						<Chevron
							direction={derivedControllerVisibility ? 'up' : 'down'}
							extraClasses={cn(styles['chevron'], { [styles['chevron-open']]: derivedControllerVisibility })}
						/>
					</div>
				)}

				<Flex className={cn(styles['container-error-message'], styles['error-message'])}>{errorMessage}</Flex>

				{!isMobile && derivedControllerVisibility && (
					<ul
						className={cn(dropdownStyles['list'], { [dropdownStyles['show']]: derivedControllerVisibility })}
						role='listbox'
						aria-activedescendant={selected as string}
					>
						<div className={dropdownStyles['fade']} />
						<Flex column align='start' gap={3} className={dropdownStyles['scroll']} ref={dropdownRef}>
							{mappedInputOptions}
						</Flex>
					</ul>
				)}
			</div>
		);
	}
);

ControlledInput.displayName = 'ControlledInput';

export default ControlledInput;
