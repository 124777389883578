import { memo } from 'react';
import cn from 'classnames';
import variables from '@styles/export.module.scss';
import { Divider, StatusGauge, TrackingNumber } from '@components';
import { Caption, Heading, Paragraph } from '@components/typography';
import { OrderStatusPOMS, TrackingPOMS } from '@ts/poms/orders';
import styles from './Tracker.module.scss';

type TrackerProps = {
	statuses: OrderStatusPOMS[];
	tracking: TrackingPOMS[];
};

const Tracker = ({ statuses, tracking }: TrackerProps) => {
	const isCancelled = statuses.length === 2;
	const statusNumber = statuses.findLastIndex(status => status.completed) + 1;

	function mapStatuses() {
		return statuses.map(({ title, description }, index) => (
			<li key={title} className={cn({ [styles.disabled]: statusNumber < index + 1 })}>
				<Caption>{title}</Caption>
				<Paragraph>{description}</Paragraph>
			</li>
		));
	}

	return (
		<div className={styles.tracker}>
			<Heading tag='h6' style={{ marginBottom: '1.6rem' }}>
				Order tracker
			</Heading>
			<div className={styles.container}>
				<div className={styles.status}>
					<StatusGauge
						status={statusNumber}
						nodes={statuses.length}
						fillColor={isCancelled ? variables.orange3 : undefined}
					/>
					<ul className={styles['status-list']}>{mapStatuses()}</ul>
				</div>
				{tracking?.length ? (
					<>
						<Divider color={variables.gray2} alignment='horizontal' />
						<TrackingNumber
							company={tracking[0].tracking_company}
							number={tracking[0].tracking_number}
							url={tracking[0].tracking_url}
						/>
					</>
				) : null}
			</div>
		</div>
	);
};

export default memo(Tracker);
