import { Title } from '@components/typography';
import variables from '@styles/export.module.scss';
import { Divider, Flex, skeletonStyles } from '@components';

const OrderDetailsSkeletonLoader = () => {
	const mapProductSkeleton = () => (
		<Flex column gap={2}>
			{[...Array(3)].map((__, i) => (
				<li key={i} className={skeletonStyles.product}>
					<div className={skeletonStyles.image} />
					<div className={skeletonStyles['title-container']}>
						<div className={skeletonStyles['product-title']} />
						<div className={skeletonStyles.caption} />
					</div>
					<div className={skeletonStyles.price} />
				</li>
			))}
		</Flex>
	);

	const mapPriceLines = () =>
		[...Array(3)].map((__, i) => (
			<div key={i} className={skeletonStyles['price-container']}>
				<div className={skeletonStyles.line} />
				<div className={skeletonStyles.price} />
			</div>
		));
	return (
		<>
			<div className={skeletonStyles['skeleton-loader-details']}>
				<Title>Items in this order</Title>
				<div className={skeletonStyles.container}>
					{mapProductSkeleton()}
					<Divider color={variables.gray2} alignment='horizontal' />
					{mapPriceLines()}
					<Divider color={variables.gray2} alignment='horizontal' />
					<span className={skeletonStyles.total} />
				</div>
			</div>
			<div className={skeletonStyles['skeleton-loader-tracker']}>
				<Title>Order tracker</Title>
				<div className={skeletonStyles.container}>
					<div className={skeletonStyles['skeleton-loader-tracker-slot']} />
					<div className={skeletonStyles['skeleton-loader-tracker-slot']} />
					<div className={skeletonStyles['skeleton-loader-tracker-slot']} />
					<Divider color={variables.gray2} alignment='horizontal' />
					<div className={skeletonStyles['skeleton-loader-tracker-slot']} />
				</div>
			</div>
		</>
	);
};

export default OrderDetailsSkeletonLoader;
