import { SVGAttributes } from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

type FileProps = SVGAttributes<SVGElement> & { color?: string };

const File = ({ color = '#000', ...rest }: FileProps) => {
	return (
		<AccessibleIcon.Root label='filter'>
			<svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
				<g id='icon-image'>
					<path id='coolicon' d='M25.0007 28.333H15.0007C14.0802 28.333 13.334 27.5868 13.334 26.6663V13.3329C13.334 12.4124 14.0802 11.6663 15.0007 11.6663H20.834C20.8415 11.6653 20.849 11.6653 20.8565 11.6663H20.8615C20.8694 11.6687 20.8774 11.6704 20.8857 11.6713C20.9591 11.676 21.0317 11.6903 21.1015 11.7138H21.114H21.1265H21.1365C21.1519 11.7245 21.1664 11.7365 21.1798 11.7496C21.2706 11.7899 21.3533 11.8464 21.424 11.9163L26.424 16.9163C26.4938 16.9869 26.5503 17.0697 26.5907 17.1604C26.5982 17.1788 26.604 17.1963 26.6098 17.2154L26.6182 17.2388C26.6414 17.3083 26.6552 17.3806 26.659 17.4538C26.6597 17.4621 26.6617 17.4702 26.6648 17.478V17.483C26.6662 17.4884 26.667 17.494 26.6673 17.4996V26.6663C26.6673 27.1084 26.4917 27.5323 26.1792 27.8449C25.8666 28.1574 25.4427 28.333 25.0007 28.333ZM17.5007 23.333L15.0007 26.6663H25.0007L20.834 20.833L18.334 24.1663L17.5007 23.333ZM17.084 19.1663C16.4023 19.1672 15.8471 19.7142 15.8358 20.3958C15.8246 21.0773 16.3616 21.6422 17.0429 21.6656C17.7242 21.6889 18.2985 21.1621 18.334 20.4813V20.723V20.4163C18.334 19.7259 17.7743 19.1663 17.084 19.1663ZM20.834 13.3329V17.4996H25.0007L20.834 13.3329Z' fill={color}/>
				</g>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default File;
