import { Button, Close, Flex, Modal } from '@components';
import { Heading, Paragraph } from '@components/typography';
import styles from '@components/rxss/RxssWizard/RxssWizard.module.scss';

const CustomRxssCloseButton = ({ onOpenChange }) => {
	return (
		<div style={{ position: 'absolute', top: 0, height: '100%', width: '100%', pointerEvents: 'none' }}>
			<Modal>
				<Modal.Trigger className={styles['modal-close-button']}>
					<Close label='Close' />
				</Modal.Trigger>
				<Modal.Content customClose removePadding className={styles['modal-close']}>
					<Modal.Close className={styles['exit-modal-close-button']}>
						<Close label='Close' />
					</Modal.Close>
					<Flex column>
						<Heading tag='h5'>Are You Sure You Want to Exit?</Heading>
						<Paragraph>Entering your prescription is the fastest way to receive your new frames! You’ll lose the Rx information you’ve entered if you exit now.</Paragraph>
						<Flex gap={3} fullWidth style={{ paddingTop: '2.4rem' }}>
							<Modal.Close>
								<Button color='white' onClick={() => onOpenChange(false)}>Exit</Button>
							</Modal.Close>
							<Modal.Close style={{ width: '100%' }}>
								<Button data-rxss-continue fullWidth>Continue entering Rx</Button>
							</Modal.Close>
						</Flex>
					</Flex>
				</Modal.Content>
			</Modal>
		</div>
	);
};

export default CustomRxssCloseButton;
