import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import cn from 'classnames';
import {
	Button,
	Chevron,
	DetailsSkeletonLoader as OrderDetailsSkeletonLoader,
	Divider,
	ErrorMessage,
	Flex,
	Receipt,
	Tracker,
} from '@components';
import { Body, Heading, TypographyButton } from '@components/typography';
import useOrderDetails from '@services/poms/hooks/useOrderDetails';
import { getOrderInvoice } from '@services/poms/operations';
import { useCustomer } from '@services/shopify';
import { getShopifyIdFromGID } from '@utils/shopify';
import { useIsMobile, useIsomorphicLayoutEffect } from '@utils/hooks';
import variables from '@styles/export.module.scss';
import styles from './Details.module.scss';

type DetailsProps = {
	containerClass: string;
	orderNumber: string;
	isLogIn?: boolean;
	handleItemClick: (id: string, el?: EventTarget & HTMLDivElement) => void;
	setDetailsHeight?: React.Dispatch<React.SetStateAction<number>>;
};

const Details = forwardRef<HTMLDivElement | null, DetailsProps>(
	({ containerClass, orderNumber, isLogIn = true, handleItemClick, setDetailsHeight = undefined }, ref) => {
		const { data: order, isLoading, isError } = useOrderDetails({ id: orderNumber });
		const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
		const isRxNeeded = order?.line_items.find(line => line.rx_needed) ?? false;
		const isMobile = useIsMobile();
		const Customer = useCustomer();

		const innerRef = useRef<HTMLDivElement>(null);
		useImperativeHandle(ref, () => innerRef.current, []);

		const handleGeneratePDF = async () => {
			if (Customer.data?.id) {
				setIsInvoiceLoading(true);
				const customerId = getShopifyIdFromGID(Customer.data.id);
				const newBlob = await getOrderInvoice(customerId, order.order_number);
				const blobUrl = window.URL.createObjectURL(newBlob);
				const a = document.createElement('a');
				a.href = blobUrl;
				a.target = '_blank';
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(blobUrl);
				setIsInvoiceLoading(false);
			}
		};

		useIsomorphicLayoutEffect(() => {
			if (typeof setDetailsHeight !== 'function') return;
			// Get the height after the component updates for animation purposes
			if (!isLoading && innerRef.current) {
				const height = innerRef.current.getBoundingClientRect().height;
				setDetailsHeight(prev => {
					if (prev === height) return prev;
					return height;
				});
			}
		}, [isLoading, ref, setDetailsHeight]);

		return (
			<Flex column fullWidth fullHeight className={containerClass} ref={innerRef}>
				{isLogIn && (
					<button type='button' className={styles['back-button']} onClick={() => handleItemClick(orderNumber)}>
						<Flex align='center'>
							<Chevron direction='left' />
							<TypographyButton small>Back to My Orders</TypographyButton>
						</Flex>
					</button>
				)}
				<Flex column fullWidth fullHeight justify='start' align='center' className={styles['details-container']}>
					{order && isError ? (
						<div className={styles['error-wrapper']}>
							<Body>Order #{orderNumber}</Body>
							<ErrorMessage
								message={
									'There was an error loading your order details or the order number does not exist. Please contact our Customer Service team if there was a mistake. Otherwise, click "Back to My Orders" to see your order history'
								}
							/>
						</div>
					) : (
						<>
							<Flex column fullWidth>
								<Flex justify='between' column={isMobile}>
									<div>
										<Heading tag='h6'>Ordered on {order?.date}</Heading>
										<Body>Order #{orderNumber}</Body>
									</div>
									{/* {isLogIn && <div className={cn({ [styles['button-wrapper']]: isMobile })}>
									{order?.order_number && !isRxNeeded && (
										<Button
											onClick={handleGeneratePDF}
											disabled={isInvoiceLoading}
											showSpinner={isInvoiceLoading}
										>
											{isInvoiceLoading ? 'Fetching Invoice...' : 'Download invoice'}
										</Button>
									)}
								</div>} */}
								</Flex>
							</Flex>
							<Divider color={variables.gray2} alignment='horizontal' />
							<Flex
								fullWidth
								fullHeight
								gap={5}
								className={styles['order-details-wrapper']}
								data-is-logout={!isLogIn}
							>
								{isLoading ? (
									<OrderDetailsSkeletonLoader />
								) : order ? (
									<>
										<Receipt order={order} isLogIn={isLogIn} />
										<Tracker statuses={order.statuses} tracking={order.tracking} />
									</>
								) : null}
							</Flex>
						</>
					)}
				</Flex>
			</Flex>
		);
	}
);

Details.displayName = 'Details';

export default Details;
