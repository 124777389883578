import { Button } from "@components";

type BackButtonProps = {
	step: number;
	onClose: () => void;
	onBack: () => void;
};
const BackButton = ({ step, onClose, onBack }: BackButtonProps) => {
	return step === 0 ? (
		<Button color='white' onClick={onClose}>
				Close
		</Button>
	) : (
		<Button
			color='white'
			withChevron={true}
			chevronDirection='left'
			chevronPosition='front'
			onClick={onBack}
		>
				Back
		</Button>
	);
}

export default BackButton;
